const debounce = require("lodash/debounce");

/**
 * @function
 * @description - Mobile sort buttons event
 */
const mobileProductGridSort = function() {
    $(".refinement-btn--sort").on("click", function() {
        $.spinner().start();

        const $this = $(this);
        const url = $this.data("href");

        $this.siblings().removeClass("selected");
        $this.addClass("selected");

        $.ajax({
            url: url,
            data: { selectedUrl: url },
            method: "GET",
            success(response) {
                $(".product-grid").empty().html(response);
                $.spinner().stop();
            },
            error() {
                $.spinner().stop();
            },
        });
    });

    $(".refinement-bar button.reset").on("click", debounce(mobileProductGridSort, 500));
};

$(document).ready(function() {
    $(".filter-results").on("mousedown", function() {
        const $filterHeader = $(".filter-header");
        const $refinements = $(".refinements");

        $filterHeader.attr("data-refinement-type", "filters");
        $refinements.attr("data-refinement-type", "filters");
    });

    $(".search-results__sort-select-mobile-container").on("mousedown", ".search-results__sort-button", function(e) {
        e.preventDefault();

        const $refinementBar = $(".refinement-bar");
        const $filterHeader = $(".filter-header");
        const $refinements = $(".refinements");

        $filterHeader.attr("data-refinement-type", "sortby");
        $refinements.attr("data-refinement-type", "sortby");
        $(".refinement-bar, .modal-background").show();
        $refinementBar.siblings().attr("aria-hidden", true);
        $refinementBar.closest(".row").siblings().attr("aria-hidden", true);
        $refinementBar.closest(".tab-pane.active").siblings().attr("aria-hidden", true);
        $refinementBar.closest(".container.search-results").siblings().attr("aria-hidden", true);
        $(".refinement-bar .close").focus();
    });

    mobileProductGridSort();
});
