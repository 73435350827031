const tile = require('../product/tile');

/**
 * Update DOM elements with Ajax results
 *
 * @param {Object} $results - jQuery DOM element
 * @param {string} selector - DOM element to look up in the $results
 * @return {undefined}
 */
function updateDom($results, selector) {
    const $updates = $results.find(selector);
    $(selector).empty().html($updates.html());
}

/**
 * Build search url parameters
 *
 * @return {string} - search url parameters
 */
function buildSearchUrl() {
    var refinements = $('.refinements .tab-content .tab-pane');
    var filtersParams = '';
    var i = 0;
    refinements.each(function () {
        var selectedFilters = $(this).find('input:checkbox:checked');
        if (selectedFilters.length > 0) {
            var j = 0;
            // add to url
            i += 1;
            filtersParams += '&prefn' + i + '=' + $(this).attr('data-refinement-name') + '&prefv' + i + '=';
            selectedFilters.each(function () {
                filtersParams += encodeURIComponent($(this).attr('name'));
                j += 1;
                if (j < selectedFilters.length) {
                    filtersParams += '|';
                }
            });
        }
    });

    return filtersParams;
}

/**
 * Keep refinement panes expanded/collapsed after Ajax refresh
 *
 * @param {Object} $results - jQuery DOM element
 * @return {undefined}
 */
function handleRefinements($results) {
    updateDom($results, '.refinements');
}

/**
 * Parse Ajax results and updated select DOM elements
 *
 * @param {string} response - Ajax response HTML code
 * @return {undefined}
 */
function parseResults(response) {
    const $results = $(response);

    // Update DOM elements that do not require special handling
    [
        '.grid-header',
        '.product-grid',
        '.show-more'
    ].forEach((selector) => {
        updateDom($results, selector);
    });
}

/**
 * This function retrieves another page of content to display in the content search grid
 * @param {JQuery} $element - the jquery element that has the click event attached
 * @param {JQuery} $target - the jquery element that will receive the response
 * @return {undefined}
 */
function getContent($element, $target) {
    const showMoreUrl = $element.data('url');
    $.spinner().start();
    $.ajax({
        url: showMoreUrl,
        method: 'GET',
        success(response) {
            $target.append(response);
            $.spinner().stop();
        },
        error() {
            $.spinner().stop();
        }
    });
}

/**
 * Update sort option URLs from Ajax response
 *
 * @param {string} response - Ajax response HTML code
 * @return {undefined}
 */
function updateSortOptions(response) {
    const $tempDom = $('<div>').append($(response));
    const sortOptions = $tempDom.find('.grid-footer').data('sort-options').options;
    sortOptions.forEach((option) => {
        $(`option.${option.id}`).val(option.url);
    });
}

/**
 * Update grid with tiles on PLP after Ajax response
 */
function updateGrid() {
    var checkGrid = $('.page').attr('data-action');
    var fullGrid = $('.product-grid')[0];
    if ($('.page') && checkGrid === 'Search-Show' && fullGrid) {
        $('div.product-tile-container').each(function () {
            if (!$(this).find('.product').length) {
                $(this).remove();
            }
        });
    }
}

module.exports = {
    filter() {
        // Display refinements bar when Menu icon clicked
        $('.container').on('click', 'button.filter-results', () => {
            $('.refinement-bar, .modal-background').show();
            $('.refinement-bar').siblings().attr('aria-hidden', true);
            $('.refinement-bar').closest('.row').siblings().attr('aria-hidden', true);
            $('.refinement-bar').closest('.tab-pane.active').siblings().attr('aria-hidden', true);
            $('.refinement-bar').closest('.container.search-results').siblings().attr('aria-hidden', true);
            $('.refinement-bar .close').focus();
        });
    },

    sort() {
        // Handle sort order menu selection
        $('.container').on('click', '.js-apply-sort', function (e) {
            e.preventDefault();
            var $sortingRule = $('.sorting-bar input:radio:checked');
            if ($sortingRule.length < 1) {
                return false;
            }
            $.spinner().start();
            $(this).trigger('search:sort', $sortingRule.val());
            var url = $sortingRule.val();
            var filtersParams = buildSearchUrl();
            url += filtersParams;

            $.ajax({
                url: url,
                data: { selectedUrl: $sortingRule.val() },
                method: 'GET',
                success(response) {
                    $('.product-grid').empty().html(response);
                    $('.sorting-bar, .modal-background').hide();
                    $('body').removeClass('lock-scroll');
                    $('.js-sort').removeClass('icon-sort-blue').addClass('icon-sort');
                    $.spinner().stop();
                },
                error() {
                    $.spinner().stop();
                }
            }).done(() => {
                tile.tileLoad();
                $().vEllipsis({
                    element: '.v-ellipsis',
                    lines: 2,
                    onlyFullWords: false,
                    callback: function () {
                        $(this).mouseenter(function () {
                            $(this).siblings('.name-on-hover, .name-on-hover-plp').removeClass('d-none');
                        }).mouseleave(function () {
                            $(this).siblings('.name-on-hover, .name-on-hover-plp').addClass('d-none');
                        });
                    }
                });
            });
            return true;
        });
    },

    showMore() {
        // Show more products
        $('.container').on('click', '.show-more button', function (e) {
            e.stopPropagation();
            const showMoreUrl = $(this).data('url');
            e.preventDefault();

            $.spinner().start();
            $(this).trigger('search:showMore', e);
            $.ajax({
                url: showMoreUrl,
                data: { selectedUrl: showMoreUrl },
                method: 'GET',
                success(response) {
                    $('.grid-footer').replaceWith(response);
                    updateSortOptions(response);
                    $().vEllipsis({
                        element: '.v-ellipsis',
                        lines: 2,
                        onlyFullWords: false,
                        callback: function () {
                            $(this).mouseenter(function () {
                                $(this).siblings('.name-on-hover, .name-on-hover-plp').removeClass('d-none');
                            }).mouseleave(function () {
                                $(this).siblings('.name-on-hover, .name-on-hover-plp').addClass('d-none');
                            });
                        }
                    });
                    updateGrid();
                    $.spinner().stop();
                },
                error() {
                    $.spinner().stop();
                }
            }).done(() => {
                tile.tileLoad();
            });
        });
    },

    applyFilter: function () {
        // Handle refinement value cancel and reset click
        $('.container').on(
            'click',
            '.refinement-bar button.reset, .filter-value button, .swatch-filter button',
            function (e) {
                e.preventDefault();
                e.stopPropagation();
                $.spinner().start();
                $(this).trigger('search:filter', e);
                $.ajax({
                    url: $(this).data('href'),
                    data: {
                        page: $('.grid-footer').data('page-number'),
                        selectedUrl: $(this).data('href')
                    },
                    method: 'GET',
                    success: function (response) {
                        parseResults(response);
                        var $results = $(response);
                        handleRefinements($results);
                        $.spinner().stop();
                    },
                    error: function () {
                        $.spinner().stop();
                    }
                });
            }
        );
    },

    applySelectedFilters: function () {
        $('.container').on(
            'click',
            '.refinement-bar .js-apply-filter',
            function (e) {
                e.preventDefault();
                e.stopPropagation();
                $.spinner().start();
                // build url
                var url = $(this).data('href');
                var filtersParams = buildSearchUrl();
                url += filtersParams;

                $(this).trigger('search:filter', e);
                $.ajax({
                    url: url,
                    data: {
                        page: $('.grid-footer').data('page-number'),
                        selectedUrl: url
                    },
                    method: 'GET',
                    success: function (response) {
                        parseResults(response);
                        $('.js-close-filters').click();
                        $.spinner().stop();

                        $().vEllipsis({
                            element: '.v-ellipsis',
                            lines: 2,
                            onlyFullWords: false
                        });
                    },
                    error: function () {
                        $.spinner().stop();
                    }
                });
            }
        );
    },

    showContentTab() {
        // Display content results from the search
        $('.container').on('click', '.content-search', function () {
            if ($('#content-search-results').html() === '') {
                getContent($(this), $('#content-search-results'));
            }
        });

        // Display the next page of content results from the search
        $('.container').on('click', '.show-more-content button', function () {
            getContent($(this), $('#content-search-results'));
            $('.show-more-content').remove();
        });
    },

    showFilters() {
        $('.js-refinement').on('click', function () {
            $('.js-refinement').text('');
            $('.applied-filters').text('').addClass('d-none');
            if ($('.sorting-bar').css('display', 'block')) {
                $('.sorting-bar').hide();
                $('.js-sort').removeClass('icon-sort-blue').addClass('icon-sort');
            }

            $('.refinement-bar').show();
            $(this).removeClass('icon-refinement').addClass('icon-refinement-blue');

            if (window.matchMedia('(max-width: 767px)').matches) {
                $('.modal-background').show();
                $('.mobile-menu-container').css('z-index', '1040');
                $('body').toggleClass('lock-scroll');
            }
        });

        $('.js-close-filters').on('click', function () {
            $('.refinement-bar').hide();
            $('.js-refinement').removeClass('icon-refinement-blue').addClass('icon-refinement');
            var appliedFilters = $('.refinements input:checkbox:checked').length;
            if (appliedFilters > 0) {
                $('.applied-filters').text(appliedFilters).removeClass('d-none');
            }

            if (window.matchMedia('(max-width: 767px)').matches) {
                $('.modal-background').hide();
                $('.mobile-menu-container').css('z-index', '90002');
                $('body').toggleClass('lock-scroll');
            }
        });
    },

    showSortingRules() {
        $('.js-sort').on('click', function () {
            if ($('.refinement-bar').css('display', 'block')) {
                $('.refinement-bar').hide();
                $('.js-refinement').removeClass('icon-refinement-blue').addClass('icon-refinement');
            }

            $('.sorting-bar').show();
            $(this).removeClass('icon-sort').addClass('icon-sort-blue');

            if (window.matchMedia('(max-width: 767px)').matches) {
                $('.modal-background').show();
                $('.mobile-menu-container').css('z-index', '9000');
                $('body').toggleClass('lock-scroll');
            }
        });

        $('.js-close-sort').on('click', function () {
            $('.sorting-bar').hide();
            $('.js-sort').removeClass('icon-sort-blue').addClass('icon-sort');

            if (window.matchMedia('(max-width: 767px)').matches) {
                $('.modal-background').hide();
                $('.mobile-menu-container').css('z-index', '90002');
                $('body').toggleClass('lock-scroll');
            }
        });
    },

    countResults() {
        $('.refinements').on('change', '.custom-control-input', function () {
            const refinementsContainer = $('.refinement-bar');
            const filterUrl = $(this).closest('.refinement-btn').attr('data-href');
            refinementsContainer.spinner().start();
            $.ajax({
                url: filterUrl,
                data: {
                    page: $('.grid-footer').data('page-number'),
                    selectedUrl: filterUrl,
                    activeRefinementTab: $('.refinements .nav-tabs .nav-link.active').attr('data-refinement-name')
                },
                method: 'GET',
                success: function (response) {
                    var $results = $(response);
                    handleRefinements($results);
                    refinementsContainer.spinner().stop();
                },
                error: function () {
                    refinementsContainer.spinner().stop();
                }
            });
        });
    },

    updateGrid: updateGrid
};
